import React from 'react';
import SEO from '../components/seo';
import LayoutPortfolio from '../containers/layout-portfolio';
import LandscapeGallery from '../components/gallery/landscapeGallery';


const LandscapePage = () => {

  return (
    <LayoutPortfolio>
      <SEO title="Landscape Photos" description="Landscape photos by Anatole Tuzlak" />
      <LandscapeGallery/>
    </LayoutPortfolio>
  );
};

export default LandscapePage;
